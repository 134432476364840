// dashboard

import UserProfile from "pages/Authentication/UserProfile";
import Login from "pages/Authentication/Login";
// import ForgetPassword from "pages/Authentication/forgetPassword";

import Logout from "pages/Authentication/LogOut";
import UserList from "pages/Customer/UserList";
import Admins from "pages/Admin";
import blog from "pages/blog";
import BlogAuthor from "pages/blogAuthor";
import Media from "pages/media/mediaList";
import Jobs from "pages/job";
import ResetPassword from "pages/Authentication/ResetPassword";
import Leads from "pages/Lead";
import ForgetPassword from "pages/Authentication/forgetPassword";
import Page from "pages/Page";
import Category from "pages/Category";
// import ResetPassword from "pages/Authentication/ResetPassword";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  // { path: "/", component: Home },

  // profile

  //userlist

  { path: "/", component: Admins },
  { path: "/user-profile", component: UserProfile },
  { path: "/blog", component: blog },
  { path: "/media", component: Media },
  { path: "/blogauthor", component: BlogAuthor },
  { path: "/page", component: Page },
  { path: "/jobs", component: Jobs },
  { path: "/leads", component: Leads },
  { path: "/category", component: Category },
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
