import React, { useState, useEffect } from "react";

// Formik Validation
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
//redux
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { TailSpin } from "react-loader-spinner";
import callApi from "Common/utils/apiCaller";
import { createSelector } from "reselect";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { editProfile } from "slices/thunk";
import { headingData, loginSuccess } from "slices/auth/login/reducer";

const UserProfile = () => {
  //meta title
  document.title = "Profile | 91coderstreet";
  const userDetail = JSON.parse(window.localStorage.getItem(`authUser`));
  const dispatch = useDispatch<any>();
  const [loader, setLoader] = useState<any>();
  const [address, setAddress] = useState<string>("");
  const [render, setRender] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("admin@gmail.com");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [showAlert, setShowAlert] = useState(false);
  const selectProperties = createSelector(
    (state: any) => state.Login,
    (profile) => ({
      user: profile.user,
      error: profile.error,
      success: profile.success,
    })
  );

  const { error, success, user } = useSelector(selectProperties);
  //console.log(user?._id);
  useEffect(() => {
    if (error || success) {
      setShowAlert(true);

      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [error, success]);
  useEffect(() => {
    async function fectchAdmin(id: any) {
      // console.log(user?._id);

      const res = await callApi(`v1/admin/${id}`, "get", {});
      console.log(res);

      if (res?.data?.status === "Success") {
        dispatch(
          headingData({ contact_name: res?.data?.data?.admin?.contact_name })
        );
        setEmail(res?.data?.data?.admin?.contact_email);
        setName(res?.data?.data?.admin?.contact_name);
        setPhone(res?.data?.data?.admin?.contact_phone);
        setGender(res?.data?.data?.admin?.gender);
      }
    }
    if (user?._id) {
      fectchAdmin(user?._id);
    }
  }, [user?._id, render]);
  // useEffect(() => {
  //   if (user) {
  //     setEmail(user?.contact_email);
  //     setName(user?.contact_name);
  //   }
  // }, [user]);

  const validation = useFormic({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      contact_name: name || "admin",
      // address: address || "home",
      contact_email: email || "",
      contact_phone: phone || "",
      // pincode: pincode || "",
      gender: gender || "",
    },
    validationSchema: Yup.object({
      contact_name: Yup.string().required("Please Enter Your UserName"),
      // address: Yup.string().required("Please Enter Your Address"),
      // pincode: Yup.string().required("Please Enter Your Pincode"),
      gender: Yup.string().required("Please Enter Your Gender"),
      contact_phone: Yup.string().required("Please Enter Your Phone"),
      contact_email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      const res = await callApi(`v1/admin/${user?._id}/update`, "post", {
        admin: { ...values },
      });
      if (res && res?.data?.status === "Success") {
        setLoader(false);
        toast("Updated successfully");
        setRender(!render);
      }
      // dispatch(editProfile(values));
    },
  });

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        {/* Render Breadcrumb */}
        <BreadCrumb title="Ant Admin" pageTitle="Profile" />

        <div className="row">
          <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-1">
            {showAlert && success && (
              <div
                className="px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50"
                id="successAlert"
              >
                You have <b>successfully</b> logged in.
              </div>
            )}
            {showAlert && error && (
              <div
                className="px-4 py-3 mb-3 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50"
                id="errorAlert"
              >
                You have <b>failed</b> to log in.
              </div>
            )}

            <div className="card">
              <div className="card-body">
                <div className="flex items-center gap-3">
                  <div
                    style={{
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "2.5em",
                      height: "2.5em",
                      fontSize: "1.5em",
                      background: "#f1f5f9",
                    }}
                  >
                    <span className="avatar-md rounded-circle img-thumbnail">
                      {_.upperCase(name?.slice(0, 1))}
                    </span>
                  </div>
                  <div className="text-slate-500 dark:text-zink-200">
                    <h5 className="text-slate-500">{name || "admin"}</h5>
                    <p className="mb-1">{email || "admin@gmail.com"}</p>
                    {/* <p className="mb-0">Id no: #{idx || 1}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h5 className="mb-4">Change User Detail</h5>

        <div className="card">
          <div className="card-body">
            <form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
                <div className="xl:col-span-12">
                  <label className="inline-block mb-2 text-base font-medium">
                    User Name
                  </label>
                  <input
                    name="contact_name"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.contact_name || ""}
                  />
                  {validation.touched?.contact_name &&
                  validation.errors?.contact_name ? (
                    <div className="mt-1 text-sm text-red-500">
                      {validation.errors?.contact_name}
                    </div>
                  ) : null}
                </div>
                <div className="xl:col-span-12">
                  <label className="inline-block mb-2 text-base font-medium">
                    Email
                  </label>
                  <input
                    name="contact_email"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter User Email"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.contact_email || ""}
                  />
                  {validation.touched?.contact_email &&
                  validation.errors?.contact_email ? (
                    <div className="mt-1 text-sm text-red-500">
                      {validation.errors?.contact_email}
                    </div>
                  ) : null}
                </div>
                <div className="xl:col-span-12">
                  <label className="inline-block mb-2 text-base font-medium">
                    Phone Number
                  </label>
                  <input
                    name="contact_phone"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter Phone Number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.contact_phone || ""}
                  />
                  {validation.touched?.contact_phone &&
                  validation.errors?.contact_phone ? (
                    <div className="mt-1 text-sm text-red-500">
                      {validation.errors?.contact_phone}
                    </div>
                  ) : null}
                </div>
                <div className="xl:col-span-12">
                  <label className="inline-block mb-2 text-base font-medium">
                    Gender
                  </label>
                  <select
                    onChange={validation.handleChange}
                    value={validation.values.gender || ""}
                    name="gender"
                    id="statusgender"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  >
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  {validation.touched?.gender && validation.errors?.gender ? (
                    <div className="mt-1 text-sm text-red-500">
                      {validation.errors?.gender}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 text-center">
                <button
                  type="submit"
                  className="px-2 py-1.5 text-xs text-white btn bg-red-500 hover:text-white hover:bg-red-600 focus:text-white focus:bg-red-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-red-500/20 dark:text-red-500 dark:hover:bg-red-500 dark:hover:text-white dark:focus:bg-red-500 dark:focus:text-white dark:active:bg-red-500 dark:active:text-white dark:ring-red-400/20"
                >
                  {loader ? (
                    <div className="flex justify-center">
                      <TailSpin
                        visible={true}
                        height="20"
                        width="20"
                        color="#4fa94d"
                      />
                    </div>
                  ) : (
                    "update User Profile"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
