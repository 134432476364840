import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  flexRender,
  PaginationState,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface TableContainerProps {
  columns?: any;
  data?: any;
  tableclassName?: any;
  divclassName?: any;
  thclassName?: any;
  trclassName?: any;
  tableClass?: any;
  tdclassName?: any;
  theadclassName?: any;
  tbodyclassName?: any;
  isTfoot?: boolean;
  isBordered?: boolean;
  isPagination: boolean;
  PaginationClassName?: string;
  pageCount?: number;
  totalItems?: number;
  onPaginationChange?: OnChangeFn<PaginationState>;
  pagination?: PaginationState;
}

const TableContainer = ({
  columns,
  data,

  isPagination,
  PaginationClassName,
  onPaginationChange,
  pageCount,
  pagination,
  totalItems,
}: TableContainerProps) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    columns,
    data,
    state: {
      columnFilters,
      pagination,
    },
    pageCount: pageCount,
    onPaginationChange: onPaginationChange,
    manualPagination: true,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {
    getHeaderGroups,
    getFooterGroups,
    getRowModel,
    getPageOptions,
    setPageIndex,
    getState,
    getCanPreviousPage,
    getCanNextPage,
    nextPage,
    previousPage,
  } = table;
  const localState = getState();

  return (
    <Fragment>
      {isPagination && (
        <div className={PaginationClassName}>
          <div className="mb-4 grow md:mb-0">
            <div className="text-slate-500 dark:text-zink-200">
              Showing
              <b>
                {" "}
                {localState.pagination.pageIndex *
                  localState.pagination.pageSize +
                  data.length ===
                0
                  ? 0
                  : 1}
              </b>{" "}
              to{" "}
              <b>
                {localState.pagination.pageIndex *
                  localState.pagination.pageSize +
                  data.length}
              </b>{" "}
              of
              <b> {totalItems}</b> Results
            </div>
          </div>
          <ul className="flex flex-wrap items-center gap-2 shrink-0">
            <li>
              <Link
                to="#!"
                className={`inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto ${
                  !getCanPreviousPage() && "disabled"
                }`}
                onClick={previousPage}
              >
                <ChevronLeft className="mr-1 size-4 rtl:rotate-180"></ChevronLeft>{" "}
                Prev
              </Link>
            </li>
            {getPageOptions().map((item: any, key: number) => (
              <React.Fragment key={key}>
                {getState().pagination.pageIndex - 3 < item &&
                  getState().pagination.pageIndex + 3 > item && (
                    <li>
                      <Link
                        to="#"
                        className={`inline-flex items-center justify-center bg-white dark:bg-zink-700 size-8 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-white dark:[&.active]:text-white [&.active]:bg-custom-500 dark:[&.active]:bg-custom-500 [&.active]:border-custom-500 dark:[&.active]:border-custom-500 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto ${
                          getState().pagination.pageIndex === item && "active"
                        }`}
                        onClick={() => setPageIndex(item)}
                      >
                        {item + 1}
                      </Link>
                    </li>
                  )}
              </React.Fragment>
            ))}
            <li>
              <Link
                to="#!"
                className={`inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto 
                ${!getCanNextPage() && ""}`}
                onClick={() => getCanNextPage() && nextPage()}
              >
                Next{" "}
                <ChevronRight className="ml-1 size-4 rtl:rotate-180"></ChevronRight>{" "}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default TableContainer;
